import React, { Component } from 'react'
// import { Link } from 'gatsby'
import _ from 'lodash'

import {
    Row
} from 'react-bootstrap'
import { withNamespaces } from 'react-i18next'

import LocalImage from '../../components/local-image'


class FactionsList extends Component {

    render() {
        const t = this.props.t
        const _t = this.props.trans
        const lang = this.props.lng

        
        let data = this.props.factionslist
        _.map( data, (obj,i) =>{ data[i]["NameEn"] = _t[obj.Name] })

        // console.log(`===== FactionsList Compontent > this.props ====`)
        // console.log(this.props)
        // console.log(lang)
        // console.log(data)

        return (
            <Row className="plist armieslist d-flex align-items-center flex-wrap">
            {
                _.map( data , (item,i) => {
                    let slug = (item.NameEn)? t(item.NameEn).match(/[A-Za-z0-9]+/g).join('_').toLowerCase() : item.ArmieID
                    return (
                        <a href={`/${lang}/map/factions/${slug}`} key={`faction-link-${i}`} className="col-12 col-md-4 col-lg-3" rel="noopener ">
                            <div className="plist-img text-center">
                            {
                                (typeof item.Logo === undefined || item.Logo === null || item.Logo === "" ) ? 
                                    null 
                                    :
                                    <LocalImage name={item.Logo.split('.')[0]} attr={{className: "img-fluid", alt:"Factions"}} />
                            }
                            </div>
                            <div className="plist-box">
                                {t(item.Name)} 
                            </div>
                        </a>
                    )
                })
            }
            </Row>
        )
    }
}

export default withNamespaces()(FactionsList)
